var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "step-drawer-layout",
    {
      attrs: {
        title: _vm.title,
        show: _vm.open,
        isForm: _vm.isForm,
        steps: _vm.steps,
      },
      on: {
        show: _vm.show,
        closed: _vm.closed,
        save: _vm.save,
        edit: _vm.edit,
      },
    },
    [
      _vm.currentStepIndex == 0
        ? _c(
            "div",
            { attrs: { slot: "form" }, slot: "form" },
            [
              _c(
                "a-form-model",
                _vm._b(
                  {
                    ref: "dataForm",
                    attrs: {
                      layout: "horizontal",
                      model: _vm.formData,
                      rules: _vm.rules[0],
                    },
                  },
                  "a-form-model",
                  _vm.layout,
                  false
                ),
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "平台名称", prop: "platformName" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入平台名称" },
                        model: {
                          value: _vm.formData.platformName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "platformName", $$v)
                          },
                          expression: "formData.platformName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "序号", prop: "sequenceNo" } },
                    [
                      _c("a-input-number", {
                        model: {
                          value: _vm.formData.sequenceNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "sequenceNo", $$v)
                          },
                          expression: "formData.sequenceNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "统筹", prop: "adminUsers" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            mode: "multiple",
                            placeholder: "请选择统筹人员",
                          },
                          on: { change: _vm.changeAdminUser },
                          model: {
                            value: _vm.selcetAdminUser,
                            callback: function ($$v) {
                              _vm.selcetAdminUser = $$v
                            },
                            expression: "selcetAdminUser",
                          },
                        },
                        _vm._l(_vm.userList, function (i) {
                          return _c("a-select-option", { key: i.id }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(i.realname) +
                                "\n          "
                            ),
                          ])
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "所属部门", prop: "selectedDepart" } },
                    [
                      _c("a-tree-select", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "tree-data": _vm.departList,
                          "show-checked-strategy": _vm.SHOW_PARENT,
                          "search-placeholder": "选择部门",
                        },
                        on: { change: _vm.changeDepart },
                        model: {
                          value: _vm.selectedDepart,
                          callback: function ($$v) {
                            _vm.selectedDepart = $$v
                          },
                          expression: "selectedDepart",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.currentStepIndex == 1
        ? _c(
            "div",
            { attrs: { slot: "form" }, slot: "form" },
            [
              _c(
                "a-form-model",
                _vm._b(
                  {
                    ref: "dataForm",
                    attrs: {
                      layout: "horizontal",
                      model: _vm.formData,
                      rules: _vm.rules[1],
                    },
                  },
                  "a-form-model",
                  _vm.layout,
                  false
                ),
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "选择部门", prop: "selectedDepart" } },
                    [
                      _c("a-tree-select", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          treeDefaultExpandAll: "",
                          "tree-data": _vm.departList,
                          "show-checked-strategy": _vm.SHOW_PARENT,
                          "search-placeholder": "选择部门",
                        },
                        on: { change: _vm.changeDepart },
                        model: {
                          value: _vm.selectedDepart,
                          callback: function ($$v) {
                            _vm.selectedDepart = $$v
                          },
                          expression: "selectedDepart",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.currentStepIndex == 2
        ? _c(
            "div",
            { attrs: { slot: "form" }, slot: "form" },
            [
              _c(
                "a-form-model",
                _vm._b(
                  {
                    ref: "dataForm",
                    attrs: {
                      layout: "horizontal",
                      model: _vm.formData,
                      rules: _vm.rules[2],
                    },
                  },
                  "a-form-model",
                  _vm.layout,
                  false
                ),
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "第三部", prop: "selectedDepart" } },
                    [
                      _c("a-tree-select", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          treeDefaultExpandAll: "",
                          "tree-data": _vm.departList,
                          "show-checked-strategy": _vm.SHOW_PARENT,
                          "search-placeholder": "选择部门",
                        },
                        on: { change: _vm.changeDepart },
                        model: {
                          value: _vm.selectedDepart,
                          callback: function ($$v) {
                            _vm.selectedDepart = $$v
                          },
                          expression: "selectedDepart",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }