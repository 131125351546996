<template>
  <step-drawer-layout
    :title="title"
    :show="open"
    :isForm="isForm"
    :steps="steps"
    @show="show"
    @closed="closed"
    @save="save"
    @edit="edit"
  >
    <div slot="form" v-if="currentStepIndex == 0">
      <a-form-model ref="dataForm" layout="horizontal" :model="formData" :rules="rules[0]" v-bind="layout">
        <a-form-model-item label="平台名称" prop="platformName">
          <a-input v-model="formData.platformName" placeholder="请输入平台名称"></a-input>
        </a-form-model-item>
        <a-form-model-item label="序号" prop="sequenceNo">
          <a-input-number v-model="formData.sequenceNo"></a-input-number>
        </a-form-model-item>
        <a-form-model-item label="统筹" prop="adminUsers">
          <a-select mode="multiple" placeholder="请选择统筹人员" v-model="selcetAdminUser" @change="changeAdminUser">
            <a-select-option v-for="i in userList" :key="i.id">
              {{ i.realname }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- <a-form-model-item label="选择助手" prop="assistantUsers">
          <a-select mode="multiple" placeholder="请选择助手用户" v-model="selcetAssUser" @change="changeAssistantUser">
            <a-select-option v-for="i in userList" :key="i.id">
              {{ i.realname }}
            </a-select-option>
          </a-select>
        </a-form-model-item> -->
        <a-form-model-item label="所属部门" prop="selectedDepart">
          <a-tree-select
            v-model="selectedDepart"
            style="width: 100%"
            :tree-data="departList"
            :show-checked-strategy="SHOW_PARENT"
            search-placeholder="选择部门"
            @change="changeDepart"
          />
        </a-form-model-item>
      </a-form-model>
    </div>

    <div slot="form" v-if="currentStepIndex == 1">
      <a-form-model ref="dataForm" layout="horizontal" :model="formData" :rules="rules[1]" v-bind="layout">
        <!-- <a-form-model-item label="选择助手" prop="assistantUsers">
          <a-select mode="multiple" placeholder="请选择助手用户" v-model="selcetAssUser" @change="changeAssistantUser">
            <a-select-option v-for="i in userList" :key="i.id">
              {{ i.realname }}
            </a-select-option>
          </a-select>
        </a-form-model-item> -->
        <a-form-model-item label="选择部门" prop="selectedDepart">
          <a-tree-select
            v-model="selectedDepart"
            treeDefaultExpandAll
            style="width: 100%"
            :tree-data="departList"
            :show-checked-strategy="SHOW_PARENT"
            search-placeholder="选择部门"
            @change="changeDepart"
          />
        </a-form-model-item>
      </a-form-model>
    </div>

    <div slot="form" v-if="currentStepIndex == 2">
      <a-form-model ref="dataForm" layout="horizontal" :model="formData" :rules="rules[2]" v-bind="layout">
        <!-- <a-form-model-item label="选择助手" prop="assistantUsers">
          <a-select mode="multiple" placeholder="请选择助手用户" v-model="selcetAssUser" @change="changeAssistantUser">
            <a-select-option v-for="i in userList" :key="i.id">
              {{ i.realname }}
            </a-select-option>
          </a-select>
        </a-form-model-item> -->
        <a-form-model-item label="第三部" prop="selectedDepart">
          <a-tree-select
            v-model="selectedDepart"
            treeDefaultExpandAll
            style="width: 100%"
            :tree-data="departList"
            :show-checked-strategy="SHOW_PARENT"
            search-placeholder="选择部门"
            @change="changeDepart"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </step-drawer-layout>
</template>

<script>
import { StepDrawerMixins } from '@/mixins/StepDrawerMixins'
export default {
  mixins: [StepDrawerMixins],

  data() {
    return {
      selcetAdminUser: [],
      selcetAssUser: [],
      rules: [{
        platformName: [{ required: true, message: '请输入平台名称', trigger: 'blur' }],
      },{
        platformName: [{ required: true, message: '请输入平台名称', trigger: 'blur' }],
      },{
        platformName: [{ required: true, message: '请输入平台名称', trigger: 'blur' }],
      }],
      urls: [{
        edit: '/platform/edit',
        add: '/platform/add',
      },{
        edit: '/platform/edit',
        add: '/platform/add',
      },{
        edit: '/platform/edit',
        add: '/platform/add',
      }],
      steps: [{ title: '基本信息' }, { title: '术语表' }, { title: '阶段' }],
      currentStepIndex: 0,
    }
  },
  methods: {
    initForm() {
      this.formData = {
        adminUsers: [],
        assistantUsers: [],
        department: {},
        guideStatus: true,
        platformId: 0,
        platformName: '',
        ruleStatus: true,
        sequenceNo: 0,
        termsStatus: true,
      }
      this.selectedUsers = []
      this.selectedDepart = ''
      this.assistantUsers = []
      this.selcetedAssistantUsers = []
    },
    saveForm(stepIndex, callback) {
      setTimeout((t) => {
        this.currentStepIndex = stepIndex
        console.log('显示... ,' + this.steps[stepIndex].title)
        callback(true)
      }, 1000)
    },
    show(stepIndex) {
      this.currentStepIndex = stepIndex
      if (this.currentStepIndex >= this.steps.length) {
        this.closed()
        return
      }
      console.log('显示... ,' + this.steps[stepIndex].title)
      this.getUserList()
      this.getDepartList()
      this.getPosition()
      this.isEdit = this.isEditForm
      if (this.isEdit) {
        console.log(this.curRow)
        this.formData = this.curRow
        const { assistantUsers, adminUsers, department } = this.formData
        this.selcetAdminUser = adminUsers.map((item) => item.userId)
        this.selcetAssUser = assistantUsers.map((item) => item.userId)
        this.selectedDepart = department == null ? '' : department.departName
      }
    },

    changeAdminUser(val) {
      console.log(val)
      this.formData.adminUsers = val.map((item) => {
        return { userId: item }
      })
    },
    changeAssistantUser(val) {
      this.formData.assistantUsers = val.map((item) => {
        return { userId: item }
      })
    },

    setFormData() {
      const { assistantUsers, adminUsers, department } = this.curRow
    },
  },
  created() {
    this.initForm()
  },
}
</script>

<style lang='stylus'></style>