import {
  getUserList,
  queryIdTree,
  ajaxGetDictItems
} from "@/api/api";
import {
  postAction,
  getAction,
  putAction
} from "@/api/manage";
import {
  TreeSelect
} from 'ant-design-vue';
import moment from 'moment';
import JDate from '@/components/jeecg/JDate'

const SHOW_PARENT = TreeSelect.SHOW_PARENT;
export const StepDrawerMixins = {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    isForm: {
      type: Boolean,
      default: false,
    },
    isEditForm: {
      type: Boolean,
      default: false,
    },
    curRow: {
      type: Object,
      default: () => { },
    },
  },
  components: {
    JDate
  },
  data() {
    return {
      formData: {},
      layout: {
        labelCol: {
          span: 5
        },
        wrapperCol: {
          span: 15
        },
      },
      stageList: [],
      userList: [],
      departList: [],
      selectedUsers: [],
      selectedDepart: '',
      selectedDeparts: [],
      defaultDeparts: [],
      positionList: [],
      assistantUsers: [],
      platformList: [],
      selcetedAssistantUsers: [],
      positionSelected: '',
      isEdit: false,
      isSaved: false,
      SHOW_PARENT,
    }
  },
  watch: {
    isEditForm(n) {
      if (n) {
        this.edit()
      }
    }
  },
  computed: {
    title() {
      if (!this.isForm) return "详情"
      return '新建'
    }
  },
  methods: {
    moment,

    closed() {
      const self = this
      if (this.isSaved || !this.isEdit) {
        self.exit()
      } else {
        this.$confirm({
          title: '提示',
          content: '您尚未保存数据，确定要退出吗？',
          onOk() {
            self.exit()
          }
        })
      }

    },
    exit() {
      this.$emit('closed');
      this.initForm()
      this.isEdit = false;
      this.isSaved = false
    },
    getUserList() {
      getUserList()
        .then(({
          success,
          data
        }) => {
          this.userList = data.records
        })
    },
    getStageList() {
      getAction('/stage/list')
        .then(({
          success,
          data
        }) => {
          this.stageList = data
          console.log(data);
        })
    },
    getDepartList() {
      queryIdTree().then(({
        result
      }) => {
        console.log(data);
        this.departList = data
      })
    },

    getDicts() {
      ajaxGetDictItems('terms_template_type').then(res => {
        this.typesList = res.data
      })
      ajaxGetDictItems('terms_template_field').then(res => {
        this.typeTitleList = res.data
      })
    },

    changeDepart(v, t, {
      triggerNode
    }) {
      this.formData.department = {
        departId: triggerNode.value,
        departName: triggerNode.title,
      }
    },
    changeDepartList(keys, t, {
      triggerNode
    }) {
      console.log(keys);
      this.formData.departments = keys.map(item => {
        return {
          departId: item
        }
      })
    },

    positionChange(id, option) {
      console.log(option);
      this.getAssistantUsers(id)
    },
    getPosition() {
      getAction('/sys/position/list')
        .then(({
          data
        }) => {
          console.log(data);
          this.positionList = data.records
        })
    },
    getAssistantUsers(id) {
      getAction('/sys/user/query_by_position', {
        positionId: id
      })
        .then(({
          data
        }) => {
          console.log(data);
          this.assistantUsers = data
        })
    },
    getPlatformList() {
      getAction('/platform/list').then(({
        result
      }) => {
        console.log(data);
        this.platformList = data.records
      })
    },
    save(stepIndex, callback) {
      const self = this;
      this.setFormData()
      console.log('保存...');
      console.log(this.formData);

      let editUrl = this.urls[stepIndex].edit;
      let addUrl = this.urls[stepIndex].add;

      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          if (self.isEdit || self.isEditForm) {
            self.formData.platformId = self.curRow.platformId
            putAction(editUrl, self.formData)
              .then(res => {
                console.log(res);
                if (res.success) {
                  self.$message.success(res.message)
                  self.isSaved = true
                  callback(true)
                } else {
                  callback(false)
                  self.$message.error(res.message)
                }
              })
          } else {
            postAction(addUrl, self.formData)
              .then(res => {
                console.log(res);
                if (res.success) {
                  self.$message.success(res.message)
                  self.isSaved = true
                  callback(true);
                } else {
                  callback(false);
                  self.$message.error(res.message)
                }
              })
          }
        } else {
          callback(false);
        }
      });
    },
    edit() {
      this.isEdit = true
      this.isSaved = false
      console.log('编辑...');
      this.formData = this.curRow;

    },
  }
}